<template>
  <div class="flex flex-col w-full">
    <div class="tabs flex absolute w-full pr-12">
      <lf-scrollable-menu
        :scroll-length="150"
        :styling-options="{
          buttonsTransparent: true,
          offsetRightClasses: 'top-10 -right-12',
          offsetLeftClasses: 'top-10 -left-12'
        }"
      >
        <div class="flex space-x-2">
          <div
            v-for="tab in tabs"
            :key="tab.id"
            :class="
              activeTab === tab.id
                ? 'bg-gray-100 mb-3 z-2 h-[143px] border border-gray-20 border-b-0 rounded-t'
                : 'bg-white h-33 pt-3 px-0.5'
            "
            class="cursor-pointer text-sm font-medium text-gray-600 focus:outline-none w-46"
            data-cy="folder-tab"
            @click="changeTab(tab.id ?? '')"
          >
            <div
              class="h-full rounded-md p-3 space-y-2"
              :class="{
                'shadow border-t border-gray-100': activeTab !== tab.id
              }"
            >
              <div class="flex items-center">
                <div class="truncate" data-cy="tab-title">
                  {{ tab.is_published ? tab.name : $t("PRESETS.NO_PRESET") }}
                </div>
                <div v-if="tab.id === assignedPreset">
                  <icon-base
                    icon="success-round-big"
                    class="ml-1 text-green-500"
                  />
                </div>
              </div>
              <div
                class="text-xs text-gray-500 font-light truncate whitespace-pre-wrap"
                data-cy="tab-description"
              >
                {{ truncateWithEllipsis(tab.description || "", 80) }}
              </div>
            </div>
          </div>
        </div>
      </lf-scrollable-menu>
    </div>
    <div class="tab-content p-5 z-1 mt-36">
      <slot></slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import type { PropType } from "vue";
import { truncateWithEllipsis } from "@/helpers/formatting";
import type { PermissionsPreset } from "@/models/permissionsPreset";

const props = defineProps({
  tabs: {
    type: Array as PropType<Partial<PermissionsPreset>[]>,
    required: true
  },
  activeTabId: {
    type: String,
    default: ""
  },
  assignedPreset: {
    type: String,
    default: ""
  }
});

const emit = defineEmits<{
  (eventName: "change", tabId: string): void;
}>();

const activeTab = ref<string>(props.activeTabId || props.tabs[0]?.id || "");

const changeTab = (tabId: string) => {
  activeTab.value = tabId ?? "";
  emit("change", tabId);
};
</script>
<style scoped>
.tabs > div {
  @apply cursor-pointer text-sm font-medium focus:outline-none focus:ring rounded-t-md;
}
.tabs > div:focus {
  @apply bg-gray-100 text-gray-900;
}

.tabs > div.active {
  @apply bg-gray-100 text-gray-900;
}

.tab-content {
  @apply p-4 border border-gray-200 rounded-b-md bg-gray-100;
}
</style>
